<template>
  <div id="app">
    <Header v-if="!whiteName.includes($route.name)" @onQRCodeLoad="onQRCodeLoad"></Header>
    <div class="iv-app-content"
         :class="{'iv-all-height': $route.name === 'login',
      'iframe-layout' : $route.name === 'voiceAdviceList',
			'remove-margin':whiteName.includes($route.name),
			'iv-remove-padding': removePaddingBottomRoutes.includes(routeName)}">
      <router-view/>
    </div>
    <!--		<div class="services" v-show="!whiteName.includes($route.name)">-->
    <!--			<Poptip trigger="hover" placement="left">-->
    <!--				<div slot="content">-->
    <!--					&lt;!&ndash; <img style="width: 190px;" :src="require('@/assets/images/fu/erweima.png')"> &ndash;&gt;-->
    <!--					<div class="erweima"></div>-->
    <!--					<span class="iv-fc-85 iv-fs-16 iv-m-t-12" style="display: block;">欢迎扫码关注</span>-->
    <!--				</div>-->
    <!--				<div class="iv-erweima"></div>-->
    <!--				&lt;!&ndash; <img class="iv-erweima" :src="require('@/assets/images/fu/erweima.png')"> &ndash;&gt;-->
    <!--			</Poptip>-->
    <!--			<span class="wenzi" ><span>微信端</span></span>-->
    <!--		</div>-->
  </div>
</template>

<script>
import {
  mapMutations
} from 'vuex'
import Header from '@/views/header'
import QRCode from 'qrcodejs2'
import {
  whiteList
} from "./config/interceptors/whiteList.js"

export default {
  name: 'App',
  data() {
    return {
      whiteName: whiteList,
      routeName: '',
      removePaddingBottomRoutes: [],
      //机器人图片地址
      // imgUrl: require('@/assets/images/service.jpg'),
      imgUrl: require('@/assets/images/fu/zhayan.gif'),
      // 机器人链接地址
      ccoServiceUrl: process.env.NODE_ENV !== 'production' ? 'http://chatrobottest.boe.com.cn/chat/CCOService' :
          'http://chatrobot.boe.com.cn/chat/CCOService'
    }
  },
  methods: {
    ...mapMutations({
      skin: 'SKIN'
    }),
    //移除padding-bottom
    setStyle() {
      this.routeName = this.$route.name
      this.removePaddingBottomRoutes = ['activityList', 'chatrobot']
    },
    //机器人切换图片
    mouseenter() {
      this.imgUrl = require('@/assets/images/fu/erweima.gif')
    },
    mouseleave() {
      this.imgUrl = require('@/assets/images/fu/zhayan.gif')
    },
    goCcoServeices() {
      this.$router.push({
        name: 'chatrobot'
      })
      // window.open(this.ccoServiceUrl, '_blank')
    },
    generateQRCode(id, value) {
      const node = document.querySelector(id)
      if (!node) return
      document.querySelector(id).innerHTML = ''
      new QRCode(document.querySelector(id), {
        text: value,
        with: 190,
        height: 190,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.Q
      })
      this.$nextTick(() => {
        document.querySelector(id).title = ''
      })
    },
    onQRCodeLoad(url) {
      // ?access_token=${sessionStorage.getItem('token')}
      let wechatUrl = `${url}wechat/page/loading/loading/loading.html`
      this.generateQRCode('.iv-erweima', wechatUrl)
      this.generateQRCode('.erweima', wechatUrl)
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.name != 'welcome') {
        let brame = document.querySelector('.iv-breadcrumb-wrapper')
        if (brame) {
          brame.style.display = 'block'
        }
      } else {
        let brame = document.querySelector('.iv-breadcrumb-wrapper')
        if (brame) {
          brame.style.display = 'none'
        }
      }
      this.setStyle()
    }
  },
  components: {
    Header
  }
}
</script>

<style lang="scss">
/*全局样式注入*/
@import '@/assets/css/base/index.scss';

body,
html,
#app {
  #app {
    font-family: 'Microsoft YaHei', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F5F7FA;
    height: 100vh;
    overflow: hidden;
  }
}

body {
  overflow-x: hidden !important;
  scrollbar-width: thin;
}

.iv-app-content {
  overflow-y: auto;
  margin-top: 64px;
  height: calc(100vh - 64px);
  padding-bottom: 46px;
  // background-color: #fff;
}

.remove-margin {
  margin-top: 0px;
}

.iv-remove-padding {
  padding-bottom: 0;
}


.iv-all-height {
  height: 100vh;
  margin-top: 0;
}
.iframe-layout{
  // padding: 0;
  height: 100%;
  padding-bottom: 0;
}
::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #c2c2c2;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background: rgba(250, 250, 250, .9);
}

// 机器人
.services {
  position: fixed;
  // bottom: 150px;
  bottom: 45%;
  right: 24px;
  z-index: 10;
  width: 48px;
  height: 48px;
  text-align: center;
  font-size: 12px !important;
  color: rgb(20, 162, 219);

  span {
    font-family: "Microsoft YaHei" !important;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .iv-erweima {
    img {
      width: 42px;
      height: 42px;
    }
  }

  .erweima {
    img {
      width: 190px;
      height: 190px;
    }
  }
}

.wenzi {
  display: inline-block;
  width: 60px;
  position: relative;

  span {
    position: absolute;
    display: inline-block;
    width: 78px;
    left: -15px;
    top: -17px;
    transform: scale(.8);
    font-family: "Microsoft YaHei" !important;
  }
}
</style>
