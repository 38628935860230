import store from '@/store'
import { routes } from '@/router'
import { prefixPath, enableDynamic, staticDir } from '@/config'
import Sortable from 'sortablejs'
import axios from '@/plugins/axios'
const operateText = '操作'
const isProd = process.env.NODE_ENV === 'production'
/**
 * 时间戳转换
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @param {Number} tamp 时间戳
 * @param {String} fmt 格式
 */
export function timeFormat(tamp, fmt) {
  if (!tamp) return ''
  let d = new Date(tamp)
  fmt = fmt || 'yyyy-MM-dd'
  if (!d || !tamp) return ''
  let o = {
    'M+': d.getMonth() + 1, //月份
    'd+': d.getDate(), //日
    'h+': d.getHours() % 24 == 0 ? 0 : d.getHours() % 24, //小时
    'H+': d.getHours(), //小时
    'm+': d.getMinutes(), //分
    's+': d.getSeconds(), //秒
    'q+': Math.floor((d.getMonth() + 3) / 3), //季度
    'S': d.getMilliseconds() //毫秒
  }
  let week = {
    '0': '/u65e5',
    '1': '/u4e00',
    '2': '/u4e8c',
    '3': '/u4e09',
    '4': '/u56db',
    '5': '/u4e94',
    '6': '/u516d'
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '/u661f/u671f' : '/u5468') : '') + week[d.getDay() + ''])
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}
/**
 * @description 根据当前路由获取面包屑
 * @params {Array} menus 菜单列表
 * @params {String} name 当前路由name
 * @return {Array} routes 当前路由列表
 */
export const getMenuByRouter = (menus, name) => {
  let routes = []
  //获取面包屑组件
  let crumbsList = sessionStorage.getItem('crumbsList')
  function DFS(menu, callback) {
    //防止不是数组
    menu = [...menu]
    let res = menu.find((item, index) => {
      if (item.children && item.children.length) {
        return DFS(item.children)
      }
      if (item.id === name) return item
    })
    res && routes.unshift(res)
    return res
  }
  DFS(menus)
  if (routes.length <= 1) {
    crumbsList = crumbsList && JSON.parse(crumbsList)
    routes = crumbsList || []
  } else sessionStorage.removeItem('crumbsList')
  store.commit('CRUMBS_LIST', routes)
  store.commit('CURRENT_MENU', routes[routes.length - 1])
  return routes
}

/**
 * @description 根据当前路由名称获取面包屑
 * @params {Array} menus 菜单列表
 * @params {String} name 当前路由name
 * @return {Array} routes 当前路由列表
 */
export const getMenuNameByRouter = () => {
  let menuNames = {}
  return (name) => {
    if (menuNames[name]) return menuNames[name]
    const menuList = routes.find(item => item.path === '/index').children
    const eachName = function (list) {
      list.some(item => {
        if (item.name === name) {
          return (menuNames[name] = item.meta.title)
        }
        if (item.children && item.children.length) {
          eachName(item.children)
        }
      })
    }
    eachName(menuList)
    return menuNames[name]
  }
}
/**
  *[imagePath {Function} 全局图片路径处理函数 ]
  * @param {string} type 图片类型，根据类型判断返回默认图片
  * @param {string} 图片Id 没有则为空，返回对应类型默认图片
  * @returns {string} 返回合并后图片路径
  */
export const imagePath = (type, imgId) => {
  if (!type) throw new Error('请输入图片类型')
  if (imgId) {
    return `${prefixPath}api-file/portal/file/stream/${imgId}?origin=true`
  }
  switch (type) {
    case 'course':
      return require('@/assets/images/course/cover-image-1.png')
    case 'todo':
      return require('@/assets/images/guanxijiezhuan.png')
    case 'headImg':
      return require('@/assets/images/user/header-3.png')
    case 'user':
      return require('@/assets/images/user/header-3.png')
    case 'album':
      return '../../assets/images/album-default.png'
    case 'zero':
      return require('@/assets/images/zero.png')
  }
}
/**
  *[getPropByPath {Function} 根据path遍历获取object值 ]
  * @param {Object} obj 图片类型，根据类型判断返回默认图片
  * @param {String} path 循环的字符串，例如 'a.b.c'
  * @returns {Function} 返回获取后的值
  */
export const getPropByPath = (context, obj = {}) => {
  return (path = []) => {
    let model = context[obj]
    path = path.split('.')
    for (let key of path) {
      model = model[key] || ''
    }
    return model
  }
}
/**
  *[levelLinkage {Function} 联动关系处理方法 ]
  * @param {Array} arr 存放ref、isLink的数组
  * @param ref 组件的ref属性
  * @param isLink 是否需要上级的value作为本请求的参数, true不需要   false需要或不传
  * 需配合 data-dictionary组件使用
*/
export const levelLinkage = function (arrs, obj) {
  arrs.forEach((item, index) => {
    let ref = this.$refs[item.ref]
    if (ref.alreadySelect) {
      let a = ref.alreadySelect
      if (arrs[index + 1]) {
        if (obj) {
          this.$refs[arrs[index + 1].ref].getDictionary(a, obj)
        } else {
          this.$refs[arrs[index + 1].ref].getDictionary(a)
        }
      } else {
        return false
      }
    }
    ref.$on('on-change', function (val) {
      onChange({
        val,
        index
      })
    })
  })
  const onChange = ({ val, index }) => {
    let refNext
    let isLink
    if (arrs[index + 1]) {
      refNext = this.$refs[arrs[index + 1].ref]
      isLink = arrs[index + 1].isLink
      refNext.clearOption()
    } else {
      isLink = arrs[index].isLink
      refNext = this.$refs[arrs[index].ref]
    }
    if (val) {
      if (!isLink) {
        refNext.getDictionary(val, obj)
      } else {
        refNext.getDictionary()
      }
    }
  }
}
/**
 * @constructor {Function} 创建拖拽排序
 * @param {Object} DOM对象
 * @param {Object} 配置对象
 */
export const createSortable = function (el, options) {
  return Sortable.create(el, options)
}
export function trigger(el, type) {
  //初始化HTML事件，如果需要自定义事件，请参考customEvent
  const e = document.createEvent('HTMLEvents')
  e.initEvent(type, true, true)
  el.dispatchEvent(e)
}
//query转换字符串
export const stringifyQuery = function (obj) {
  function encode(str) {
    return encodeURI(str)
  }
  let res = obj ? Object.keys(obj).map(function (key) {
    let val = obj[key]
    if (val === undefined) {
      return ''
    }
    return encode(key) + '=' + encode(val)
  }).filter(function (x) { return x.length > 0 }).join('&') : null
  return res ? ('?' + res) : ''
}
//拦截器工厂函数
export const interceptorFactory = function (options = {}) {
  //拦截器抽象类
  class InterceptorAbstract {
    handlers = null
    use(cbSet) {
      this.handlers.push(cbSet)
    }
  }
  class Request extends InterceptorAbstract {
    handlers = []
    constructor() {
      super()
      this.use(options.request)
    }
  }
  class Response extends InterceptorAbstract {
    handlers = []
    constructor() {
      super()
      this.use(options.response)
    }
  }
  class Factory {
    chain = []
    request = new Request()
    response = new Response()
    promise = Promise.resolve()
    constructor() {
      this.chain.push(...this.request.handlers, options.dispatch, ...this.response.handlers)
    }
    exec() {
      this.chain.forEach(currentChain => {
        this.promise = this.promise.then(currentChain.resolve, currentChain.reject)
      })
      return this.promise
    }
  }
  return new Factory()
}
//向上查找父组件
export const findComponentUpward = function (context, componentName) {
  let parent = context.$parent
  let name = parent.$options.name
  let componentNameList = Array.isArray(componentName) ? componentName : [componentName]
  while (parent) {
    if (componentNameList.indexOf(name) > -1) break
    parent = parent.$parent
    name = (parent && parent.$options.name) || name
  }
  return parent
}
//向下查找子组件
export function findComponentDownward(context, componentName) {
  const childrens = context.$children
  let children = null
  if (childrens.length) {
    for (const child of childrens) {
      const name = child.$options.name
      if (name === componentName) {
        children = child
        break
      } else {
        children = findComponentDownward(child, componentName)
        if (children) break
      }
    }
  }
  return children
}
export function isNaN(val) {
  // eslint-disable-next-line
  return val !== val
}

// IE9的判断
export function isIE9(version = 9) {
  let UA = window.navigator.userAgent.toLowerCase()
  // ie9 兼容性视图
  const compatibility = 7
  let IEFlag = /trident/
  let IE9Flag = /msie\s*(\d+)|rv:(\d+)/
  if (!IEFlag.test(UA)) return false
  let result = (UA || '').match(IE9Flag)
  result = ~~(result[1] || result[2])
  return result === version || result === compatibility
}

export function isIE() {
  let UA = window.navigator.userAgent.toLowerCase()
  let IEFlag = /trident/
  if (IEFlag.test(UA)) return true
  return false
}

// 断言函数
export function assert(condition, msg) {
  return new Promise((resolve, reject) => {
    if (!condition) {
      reject(new Error(msg || `断言失败，请检查判断条件！`))
    }
    resolve()
  })
}
//简版
export class Observer {
  constructor() {
    this.myMaps = new WeakMap()
  }
  regist(obj, fn) {
    let current = this.myMaps.get(obj)
    if (current) console.warn('current object Already regist, this action will be overwrite it！')
    this.myMaps.set(obj, fn)
    obj.$$relation = this.myMaps
  }
  fire(obj = {}, params = '') {
    // 触发父窗口方法
    let parent = obj.opener
    if (!parent || !parent.$$relation) {
      console.warn(`this window has not been new open！`)
      return
    }
    let fn = parent.$$relation.get(parent)
    fn && fn(params)
  }
  destroy(obj = {}) {
    //obj.$$relation = null
    //this.myMaps.delete(obj)
  }
}

// tips公共函数，供自定义ajax
export const mergeTips = function (data = {}, columnsTable = []) {
  let { item = {} } = data, columns, resTail
  if (enableDynamic && item?.columns) {
    resTail = columnsTable[columnsTable.length - 1];
    // 配置操作列key
    (resTail.isOperate || resTail.title === operateText) && (resTail.key = 'operate')
    try {
      columns = typeof item.columns === 'string' ? JSON.parse(item.columns) : item.columns
      let result = []
      columns.forEach((column) => {
        let res = columnsTable.find(item => item.key === column.key)
        if (!res) return
        delete res.width
        result.push(Object.assign(res, column))
      })
      //头尾补充
      if (!columnsTable[0].key === '_sort' || columnsTable[0].key === '_drag' || columnsTable[0].type === 'selection') {
        result.unshift(columnsTable[0])
      }
      columnsTable = result
    } catch (e) {
      console.error(`动态字段序列化失败，请查看drag-table-inine组件, ${e}`)
    }
  }
}

export const createObjectURL = function () {
  return window.URL.createObjectURL.apply(this, arguments)
}

export const revokeObjectURL = function () {
  return window.URL.revokeObjectURL.apply(this, arguments)
}
export const downLoad = function (url = '', data = {}, fileName = '', winMode) {
  let params = Object.keys(data).reduce((cur, next) => {
    return (cur += `${next}=${data[next]}&`)
  }, '?').slice(0, -1)
  url = prefixPath.slice(0, url.indexOf('/') === 0 ? -1 : 0) + url + params
  // 匹配token
  if (!/access_token=/i.test(url)) {
    url += `&access_token=${getToken()}`
  }
  // 优先支持H5下载
  if ('download' in HTMLAnchorElement.prototype) {
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', fileName)
    anchor.style.display = 'none'
    document.body.appendChild(anchor)
    //DOM追加为异步，在下一次tick中获取，避免强制回流
    setTimeout(() => {
      anchor.click()
      document.body.removeChild(anchor)
      // blob协议下，我们需要手动释放会话
      if (winMode) {
        setTimeout(() => {
          revokeObjectURL(anchor.href)
        }, 250)
      }
    }, 16)
    return
  }
  //ajax下载
  const myBlob = window.Blob || window.MozBlob || window.WebKitBlob
  const URL = window.URL || window.WebKitURL || window.MozURL
  if (myBlob && URL) {
    return axios({
      url,
      method: 'GET',
      response: 'blob'
    }).then(({ data }) => {
      const url = createObjectURL(data)
      window.open(url)
      revokeObjectURL(url)
    })
  }
  window.open(url)
}
// 图片加载
export const loadImg = function (path) {
  const src = isProd
    ? `${staticDir}${store.state.skin}/skin/${path}`
    : `/skin/${path}`
  return `${src}?${new Date().getTime()}`
}

// BFS loop
export const BFS = function BFS(data = [], visitor = function () { }, ckey = 'children') {
  let queue = [...data]
  while (queue.length) {
    let item = queue.shift() || {}
    if (item[ckey]) {
      queue.push(...item[ckey])
    }
    visitor(item)
  }
}

// orgClassify

export const OrgCategory = function () {
  return {
    getDW() {
      return '党委'
    },
    getDZZ() {
      return '党总支'
    },
    getDZB() {
      return '党支部'
    },
    getDXZ() {
      return '党小组'
    },
    getDZ() {
      return '党组'
    }
  }
}

// get token

export const getToken = function () {
  return sessionStorage.getItem('token')
}

//decorate token, because a lot of localstorage was used to get the token that before

export const decorateToken = function () {
  // let getItem = localStorage.getItem
  // localStorage.getItem = function (key) {
  //   if (key === 'token') return sessionStorage.getItem(key)
  //   return getItem.call(localStorage, key)
  // }
}

export const encode = function (field, value = '') {
  if (field) {
    return field || btoa(encodeURIComponent(field))
  }
  return value
}

export const decode = function (field, value = '') {
  if (field) {
    return field || decodeURIComponent(atob(field))
  }
  return value
}
//在线学习跳转
export const jumpElearningPage = (successUrl, params) => {
  const prefix = sessionStorage.getItem('elearningName')
  let query = ''
  for (const k in params) {
    query += `${k}=${params[k]}`
  }
  console.log('query', query)
  const url = `${prefix}user/oauth/dj/token?scope=${query ? `&${query}` : ''}&successUrl=${encodeURIComponent(successUrl)}&token=${sessionStorage.getItem('token')}`
  window.open(url)
}
/**
 * 设置不可选日期
 * @param {Array} keys 字段key数组对象
 * @param {Date} disabledDate 不可选日期
 * @param {Object} formList 表单渲染后的dynamicList
 */
export function setMinDate(keys, disabledDate, list) {
  // let list = this.$refs.ComputedFormLayout.dynamicList
  list.map(item => {
    if (keys.includes(item.key)) {
      item.props.option = {
        disabledDate(date) {
          return date && date.valueOf() < new Date(disabledDate)
        }
      }
    }
  })
}

/**
 * 转换时间格式
 * @param {Array} keys 字段key数组对象
 * @param {Date} disabledDate 不可选日期
 * @param {Object} formList 表单渲染后的dynamicList
 */
export function formatting(time) {
  var times = new Date(time);
  var y = times.getFullYear();
  var m = times.getMonth() + 1;
  var d = times.getDate();
  var h = times.getHours();
  var mm = times.getMinutes();
  var s = times.getSeconds();
  return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
}

/**
 * 获取n天前的时间（返回Date类型n天前零点）
 * @param {Number} n 几天前
 */
export function getDateForDay(n) {
  const today = new Date();
  const sevenDaysAgo = new Date(today.getTime() - (n * 24 * 60 * 60 * 1000));
  const formattedDate = sevenDaysAgo.toISOString().split('T')[0];
  return formattedDate + " 00:00:00"
}

// const isProd = process.env.NODE_ENV === 'production'
/**
 * 获取传入时间距今的时间  *年*月
 * @param {Number} employmentTimestamp 时间戳
 */
export function calculateSeniority (employmentTimestamp) {
  let start = new Date(employmentTimestamp)
  let end = new Date()
  let years = end.getFullYear() - start.getFullYear()
  let months = end.getMonth() - start.getMonth()
  let days = end.getDate() - start.getDate()

  // 处理月份和天数小于0的情况，进行借位调整
  if (months < 0 || (months === 0 && days < 0)) {
    years--
    months += 12
  }
  if (days < 0) {
    const prevMonth = new Date(end.getFullYear(), end.getMonth() - 1, end.getDate())
    days += new Date(prevMonth.getFullYear(), prevMonth.getMonth(), 0).getDate()
    months--
  }
  return `${years}年${months}月`
}