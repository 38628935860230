// 消息跳转根据msgType映射新平台iframe name，为了处理发送过去的数据向下postMessage
export const frameNameMap = {
  djt: 'djt',
  YWX009: 'djt'
}

/**
 * 待办消息跳转
 * 参数：
 * msgId 消息id
 * token
 * orgId
 * msgType jiban 接诉即办 ｜ djt 党建通
 * msgUrl 详情地址
 * businessId 党建通对应的消息id 针对djt的字段，用于获取跳转地址
 */

export function goMsgDetailPage(msgId, token, orgId, msgType, msgUrl, businessId, openType = 'redirect', itemCode) {
  let url = '';
  if (msgType && msgType === 'jiban') {//跳转到接诉即办的详情页面
    url = `${msgUrl}&access_token=${token}`
  } else if (msgType && frameNameMap[msgType] === 'djt') {
    // type NOTICE 跳转到主应用 用于post message 携带类型
    url = `${msgUrl}?token=${token}&messageId=${businessId}&orgId=${orgId}&type=NOTICE`
  } else if (msgType === 'dangjian' && itemCode !== 'initiateReviewReminder') {
    url = `${msgUrl}?token=${token}&itemId=${msgId}&orgId=${orgId}&type=NOTICE`
  } else {
    url = `/api-uum/todo/forwardTodoPage?itemId=${msgId}&access_token=${token}&currentAuthScopeId=${orgId}`
  }
  console.info(url, '====')
  // 为了兼容使用oa跳转过来，只能使用location
  if (openType === "redirect") {
    window.location.href = url
  } else {
    window.open(url)
  }
}

export function jumpFromMsg(msgId, token, type, jumpUrl, businessId, openType = 'redirect') {
  let url = ''
  if (type === 'jiban') {
    url = `${jumpUrl}&access_token=${token}`
  } else if (frameNameMap[type] === 'djt') {
    // type NOTICE 跳转到主应用 用于post message 携带类型
    url = `${jumpUrl}?token=${token}&messageId=${businessId}&type=NOTICE&from=MESSAGE`
  } else if (type === 'YWX01') {
    url = `${jumpUrl}?token=${token}&itemId=${msgId}&from=MESSAGE`
  } else {
    url = `/api-uum/innermessage/disposeMsg/get?innerMsgId=${msgId}&access_token=${token}`;
  }

  // 为了兼容使用oa跳转过来，只能使用location
  if (openType === "redirect") {
    window.location.href = url
  } else {
    window.open(url)
  }

}
