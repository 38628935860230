export const iconConfig = {
  '30th': '30th' ,//专题网站
  'xwzx': 'xinwen4', //BEZ家园
  'hdbm': 'huodong21', //最新活动
  'dcwj': 'wenjuan2', //调查问卷
  'pysb': 'pingyou', //评优申报
  'xmsb': 'xiangmu3', //项目申报
  'psdf': 'pingshen1', //评审管理
  'xwtg': 'tougao1', //新闻投稿
  // 'knsq': 'kunnan', //困难帮扶
  'jiesujiban':'jiesujiban',//12345接诉即办网站
  'xh': 'xiehui',
  // 'xszt': 'xianshangzhanting',//线上展厅
  'hlsc':'helishangcheng',//合力商城
  'dyzq': 'dangyuanzhuanqu',//党员专区


  // 'zdsy': 'guizhangzhidu1', //制度索引
  'zxxx': 'xuexi1', //在线学习
  //'yjzj': 'yijianfankui', //调研征集
  //'gjzj': 'wodewengao', //稿件征集
  //'ldyjx': 'yijianxiang', //领导意见箱
  // 'zycsxy': 'xuanchuance', //职业操守宣言
  //'wdgrxx': 'gerenxinxi', //我的个人信息
  //'zzmb': 'mubiao', //组织目标
  //'dylxyg': 'lianxiren', //党员联系员工
  // 'xlxx': 'xinling1', //心灵信箱
  // 'xsjy': 'xinsheng', //心声建议
  // 'ljzm': 'wodewengao', //心灵信箱
  //'rdsq': 'dangjian', //入党申请
  //'rtsq': 'rutuan', //入团申请
  //'rhsq': '-gonghui' //入会申请
  // 'zjyj': 'yijian1' //征集意见
  // 'fzdy': 'fazhandangyuan', // 发展党员

  // 'xh': 'xiehui' //协会入口


}

export const titleConfig = {
  'pysb': '评优申报', //评优申报
  'hdbm': '最新活动', //活动报名
  'xmsb': '项目申报', //项目申报
  'knsq': '困难帮扶', //困难帮扶
  'zxxx': '在线学习', //在线学习
  'xsjy': '心声建议', //心声建议
  //'ldyjx': '意见箱', //意见箱
  //'yjzj': '调研征集', //调研征集
  //'gjzj': '稿件征集', //稿件征集
  // 'zycsxy': '职业操守宣言', //职业操守宣言
  //'wdgrxx': '我的个人信息', //我的个人信息
  //'zzmb': '组织目标', //组织目标
  //'dylxyg': '党员联系员工', //党员联系员工
  // 'xlxx': '心灵信箱', //心灵信箱
  // 'ljzm': '廉洁证明', //心灵信箱
  'xwzx': '牡丹动态', //新闻中心
  'zdsy': '制度索引', //制度索引
  //'rdsq': '入党申请', //入党申请
  //'rtsq': '入团申请', //入团申请
  //'rhsq': '入会申请', //入会申请
  'psdf': '评审管理', //评审管理
  'dcwj': '调查问卷',
  // 'zjyj': '征集意见'
  'xwtg': '新闻投稿', //新闻投稿
  'fzdy': '发展党员', //发展党员
  '30th': '发展专题', //专题网站
  'jiesujiban': '接诉即办', //12345接诉即办网站
  'xh': '协会', //协会
  // 'xszt': '线上展厅' ,//线上展厅'
  'dyzq':'党员专区',
  'hlsc':'合力商城'
}

export const titleColor = {
  'pysb': 'linear-gradient(313.64deg, #B83DFF 2.27%, #E6B8FC 100%)', //评优申报
  'hdbm': 'linear-gradient(134.35deg, #FFE470 0%,#FFC53D  94.16%)', //活动报名 最新活动
  'xmsb': 'linear-gradient(135deg,#FFBA9D  0%, #FF804B 100%)', //项目申报
  'knsq': 'linear-gradient(135deg, #FEDE95 0%, #FCC86B 100%)', //困难帮扶
  'zxxx': 'linear-gradient(135deg, #74D4FF 0%, #40A9FF 100%)', //在线学习
  'xsjy': 'linear-gradient(135deg, #F759AB 0%, #FC92D5 100%)', //心声建议
  //'ldyjx': '意见箱', //意见箱
  //'yjzj': '调研征集', //调研征集
  //'gjzj': '稿件征集', //稿件征集
  // 'zycsxy': '职业操守宣言', //职业操守宣言
  //'wdgrxx': '我的个人信息', //我的个人信息
  //'zzmb': '组织目标', //组织目标
  //'dylxyg': '党员联系员工', //党员联系员工
  // 'xlxx': '心灵信箱', //心灵信箱
  // 'ljzm': '廉洁证明', //心灵信箱
  'xwzx': 'linear-gradient(135deg, #FFD474 0%, #FFA940 100%)', //新闻中心 牡丹动态
  'zdsy': 'linear-gradient(135deg, #FF7A45 0%, #FFB27B 100%)', //制度索引
  //'rdsq': '入党申请', //入党申请
  //'rtsq': '入团申请', //入团申请
  //'rhsq': '入会申请', //入会申请
  'psdf': 'linear-gradient(313.64deg, #3F79FD 0%, #B0C1FF 100%)', //评审管理
  'dcwj': 'linear-gradient(313.64deg, #FF4D4F 0%, #FF8487 97.65%)', // 调查问卷
  // 'zjyj': '征集意见'
  'xwtg': 'linear-gradient(313.64deg, #54D8AB 2.27%, #79F5CB 100%)', //新闻投稿
  'fzdy': 'linear-gradient(135deg, #F89E7E 0%, #DE411C 100%)', //发展党员
  '30th': 'linear-gradient(313.64deg, #00C4B1 2.27%, #79F1E5 100%)', //专题网站
  'jiesujiban': 'linear-gradient(313.64deg,#FF7661 0%, #EF9A80 100%)', //12345接诉即办网站
  'xh': 'linear-gradient(313.64deg, #FFA64A 2.27%, #E9CC73 100%)', //协会
  'xszt': 'linear-gradient(313.64deg, #2565E3 2.27%, #B74ACB 100%)',//线上展厅
  'dyzq': 'linear-gradient(313.64deg, #FB4C3E 2.27%, #FB9992 100%)',//党员专区
  'hlsc': 'linear-gradient(313.64deg, #03BBFF 0%, #1F73EA 100%)'//合力商城
}
