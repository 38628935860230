<template>
  <div>
    <Modal v-model="showModal" class-name="vertical-center-modal" title="修改密码"
            :mask-closable="false" @on-visible-change="visiableChange">
      <div class="form-content">
        <Form ref="formData" :model="formData" :rules="ruleCustom"
              :label-width="110">
          <FormItem label="请输入旧密码" prop="oldPasswd" required>
            <Input v-model="formData.oldPasswd" type="password" placeholder="请输入旧密码"></Input>
          </FormItem>
          <FormItem label="新密码" prop="newPasswd" required>
            <Input v-model="formData.newPasswd" type="password" placeholder="请输入新密码"></Input>
          </FormItem>
          <FormItem label="确认新密码" prop="newPasswd2" required>
            <Input v-model="formData.newPasswd2" type="password" placeholder="请再次输入新密码"></Input>
          </FormItem>
        </Form>
        <div class="tips">密码长度至少8位，大写字母/小写字母/数字/特殊字符3种组合。</div>
      </div>
      <div slot="footer" class="btn-content">
        <Button class="btn-item" @click="updatePassword">提交</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import {sha256} from 'js-sha256'

export default {
  // model:{
  //   prop:'isShowModal',
  //   event:'change'
  // },
  props: {
    isShowModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'));
      } else {
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      let reg = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*\d)(?=.*[\W_])|(?=.*[A-Z])(?=.*\d)(?=.*[\W_])).{8,30}$/
      if (value==='') {
        callback(new Error('请输入新密码'));
      } else if(!reg.test(value)) {
        callback(new Error('请输入正确格式的密码'));
      } else {
        callback();
      }
    };
    const validatePassCheck2 = (rule, value, callback) => {
      let regu = /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*\d)(?=.*[\W_])|(?=.*[A-Z])(?=.*\d)(?=.*[\W_])).{8,30}$/
      if (value==='') {
        callback(new Error('请输入新密码'));
      } else if(!regu.test(value)) {
        callback(new Error('请输入正确格式的密码'));
      } else if (value !== this.formData.newPasswd) {
        callback(new Error('新密码和确认密码输入不一致！'));
      } else {
        callback();
      }
    };

    return {
      showModal:false,
      formData: {
        oldPasswd: '',
        newPasswd: '',
        newPasswd2: ''
      },
      userId: '',
      ruleCustom: {
        oldPasswd: [
          {required:'true', trigger: 'blur', message: '请输入密码'}
        ],
        newPasswd: [
          {required:'true', trigger: 'blur', message: '请输入密码'},
          {validator: validatePassCheck, trigger: 'blur'}
        ],
        newPasswd2: [
          {required:'true', trigger: 'blur', message: '请输入密码'},
          {validator: validatePassCheck2, trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    open(data){
      this.showModal = data
    },
    updatePassword() {
      this.$refs.formData.validate((validate) => {
        if (!validate) return
        this.$ajax({
          url: '/api-uum/security/updatePassword',
          method: 'POST',
          data: {
            oldPasswd: sha256(this.formData.oldPasswd),
            newPasswd: sha256(this.formData.newPasswd),
            // userId: this.userId
          }
        }).then(res => {
          sessionStorage.removeItem('token')
          this.showModal = false;
          this.$emit('change')
          // this.$router.push({
          //   name: 'login'
          // })
        })
      })
    },
    cancle() {
      sessionStorage.removeItem('token')
      this.$router.push({
        name: 'login'
      });
    },
    visiableChange(res) {
      if(!res){
        this.formData= {
          oldPasswd: '',
          newPasswd: '',
          newPasswd2: ''
        }
        sessionStorage.removeItem('token')
        this.$refs.formData.resetFields()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-content {
  padding: 30px 40px 30px 20px;

  /deep/ .ivu-form-item {
    margin-bottom: 20px !important;
  }
}
.tips{
  padding-left: 30px;
  padding-top: 10px;
  color: #c0c0c0;
}

.btn-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-item {
  margin-right: 30px;
}

</style>
