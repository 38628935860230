export default {
  'YWX01': {
    title: '党建引领',
    desc: '简单说明简单说明说明',
    icon: require('@/assets/images/systemAvatar/dj.png'),
    color: '#fa2900',
    show: false
  },
  'YWX02': {
    title: '纪检',
    desc: '简单说明简单说明说明',
    icon: 'a-41',
    color: '#45bc7d',
    show: false
  },
  'YWX03': {
    title: '工会建设',
    desc: '简单说明简单说明说明',
    icon: require('@/assets/images/systemAvatar/gh.png'),
    color: '#ffc757',
    show: false
  },
  'YWX04': {
    title: '共青团',
    desc: '简单说明简单说明说明',
    icon: require('@/assets/images/systemAvatar/qt.png'),
    color: '#fa2900',
    show: false
  },
  'YWX05': {
    title: '新闻宣传',
    desc: '简单说明简单说明说明',
    icon: require('@/assets/images/systemAvatar/qx.png'),
    color: '#ffc757',
    show: false
  },
  'YWX06': {
    title: '数字CCO',
    desc: '简单说明简单说明说明',
    icon: 'a-61',
    color: '#009ad8',
    show: false
  }
}
